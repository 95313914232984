import Vue from 'vue';
import VueRouter from 'vue-router';

import HomeView from '@/views/HomeView.vue';

import { isAuthenticated, hasBroilerRoles, hasMatrixRoles, hasLayingBirdsRoles } from '@/middleware';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: true, module: 'broilerChicken' },
    component: HomeView,
    beforeEnter: (to, from, next) => {
      hasBroilerRoles(to, from, next, router, store, 'Performance');
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
  },
  {
    path: '/performance/dashboard/:id',
    name: 'PerformanceDashboard',
    meta: { layout: true, module: 'broilerChicken' },
    component: () =>
      import(
        /* webpackChunkName: "performancedashboard" */ '../views/PerformanceDashboardView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasBroilerRoles(to, from, next, router, store, 'Performance');
    },
  },
  {
    path: '/matrix/performance/dashboard/:id/:sex',
    name: 'MatrixPerformanceDashboard',
    meta: { layout: true, module: 'broilerChicken', props: true },
    component: () =>
      import(
        /* webpackChunkName: "performancedashboard" */ '../views/PerformanceDashboardView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasMatrixRoles(to, from, next, router, store, 'Performance');
    },
  },
  {
    path: '/alerts/recent/',
    name: 'RecentAlertsView',
    meta: { layout: true, module: 'broilerChicken', props: true },
    component: () =>
      import(/* webpackChunkName: "performancedashboard" */ '../views/RecentAlertsView.vue'),
      beforeEnter: (to, from, next) => {
        store.state.environmentFilter.isMatrix = false;
        store.state.environmentFilter.isLayingBirds = false;
        next()
      },
  },
  {
    path: '/alerts/history',
    name: 'AlertHistoryView',
    meta: { layout: true, module: 'broilerChicken', props: true },
    component: () =>
      import(/* webpackChunkName: "performancedashboard" */ '../views/AlertHistoryView.vue'),
      beforeEnter: (to, from, next) => {
        store.state.environmentFilter.isMatrix = false;
        store.state.environmentFilter.isLayingBirds = false;
        next(false)
      },
  },
  {
    path: '/matrix/alerts/recent/',
    name: 'MatrixRecentAlertsView',
    meta: { layout: true, module: 'broilerChicken', props: true },
    component: () =>
      import(/* webpackChunkName: "performancedashboard" */ '../views/RecentAlertsView.vue'),
      beforeEnter: (to, from, next) => {
        store.state.environmentFilter.isMatrix = true;
        store.state.environmentFilter.isLayingBirds = false;
        next()
      },
  },
  {
    path: '/matrix/alerts/history/',
    name: 'MatrixAlertHistoryView',
    meta: { layout: true, module: 'broilerChicken', props: true },
    component: () =>
      import(/* webpackChunkName: "performancedashboard" */ '../views/AlertHistoryView.vue'),
      beforeEnter: (to, from, next) => {
        store.state.environmentFilter.isMatrix = true;
        store.state.environmentFilter.isLayingBirds = false;
        next(false)
      },
  },
  {
    path: '/alerts/recent/dashboard/:id',
    name: 'AlertDashboard',
    meta: { layout: true, module: 'broilerChicken', props: true },
    component: () =>
      import(/* webpackChunkName: "performancedashboard" */ '../views/AlertDashboardView.vue'),
      beforeEnter: (to, from, next) => {
        store.state.environmentFilter.isMatrix = false;
        store.state.environmentFilter.isLayingBirds = false;
        next(false)
      },
  },
  {
    path: '/alerts/history/dashboard/:id',
    name: 'AlertHistoryDashboard',
    meta: { layout: true, module: 'broilerChicken', props: true },
    component: () =>
      import(/* webpackChunkName: "performancedashboard" */ '../views/AlertDashboardView.vue'),
      beforeEnter: (to, from, next) => {
        store.state.environmentFilter.isMatrix = false;
        store.state.environmentFilter.isLayingBirds = false;
        next(false)
      },
  },
  {
    path: '/matrix/alerts/recent/dashboard/:id',
    name: 'MatrixAlertDashboard',
    meta: { layout: true, module: 'broilerChicken', props: true },
    component: () =>
      import(/* webpackChunkName: "performancedashboard" */ '../views/AlertDashboardView.vue'),
      beforeEnter: (to, from, next) => {
        store.state.environmentFilter.isMatrix = true;
        store.state.environmentFilter.isLayingBirds = false;
        next(false)
      },
  },
  {
    path: '/matrix/alerts/history/dashboard/:id',
    name: 'MatrixAlertHistoryDashboard',
    meta: { layout: true, module: 'broilerChicken', props: true },
    component: () =>
      import(/* webpackChunkName: "performancedashboard" */ '../views/AlertDashboardView.vue'),
      beforeEnter: (to, from, next) => {
        store.state.environmentFilter.isMatrix = true;
        store.state.environmentFilter.isLayingBirds = false;
        next(false)
      },
  },
  {
    path: '/slaughter-prediction/dashboard/:id',
    name: 'SlaughterPredictionDashboard',
    meta: { layout: true, module: 'broilerChicken' },
    component: () =>
      import(
        /* webpackChunkName: "slaughterpredictiondashboard" */ '../views/SlaughterPredictionDashboard.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasBroilerRoles(to, from, next, router, store, 'Predição');
    },
  },
  {
    path: '/pcp/dashboard/:id',
    name: 'PcpDashboardView',
    meta: { layout: true, module: 'broilerChicken' },
    component: () =>
      import(
        /* webpackChunkName: "PcpDashboardView" */ '../views/PcpDashboardView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasBroilerRoles(to, from, next, router, store, 'Predição');
    },
  },
  {
    path: '/slaughter-prediction',
    name: 'SlaughterPrediction',
    meta: { layout: true, module: 'broilerChicken' },
    component: () =>
      import(/* webpackChunkName: "slaughterprediction" */ '../views/SlaughterPredictionView.vue'),
    beforeEnter: (to, from, next) => {
      hasBroilerRoles(to, from, next, router, store, 'Predição');
    },
  },
  {
    path: '/slaughter-prediction/planning',
    name: 'SlaughterPredictionPlanning',
    meta: { layout: true, module: 'broilerChicken' },
    component: () =>
      import(/* webpackChunkName: "slaughterpredictionplanning" */ '../views/SlaughterPredictionPlanningView.vue'),
    beforeEnter: (to, from, next) => {
      hasBroilerRoles(to, from, next, router, store, 'Predição');
    },
  },
  {
    path: '/sanity/dashboard/:id',
    name: 'SanityDashboard',
    meta: { layout: true, module: 'broilerChicken' },
    component: () =>
      import(
        /* webpackChunkName: "slaughterpredictiondashboard" */ '../views/SanityDashboardView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasBroilerRoles(to, from, next, router, store, 'Sanidade');
    },
  },
  {
    path: '/slaughter-prediction/planning/:id/:last_batch_id',
    name: 'SlaughterForecastDashboardView',
    meta: { layout: true, module: 'broilerChicken' },
    component: () =>
      import(
        /* webpackChunkName: "SlaughterForecastDashboardView" */ '../views/SlaughterForecastDashboardView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasBroilerRoles(to, from, next, router, store, 'Predição');
    },
  },
  {
    path: '/matrix/sanity/dashboard/:id',
    name: 'MatrixSanityDashboard',
    meta: { layout: true, module: 'broilerChicken' },
    component: () =>
      import(
        /* webpackChunkName: "slaughterpredictiondashboard" */ '../views/SanityDashboardView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasMatrixRoles(to, from, next, router, store, 'Sanidade');
    },
  },
  {
    path: '/sanity',
    name: 'Sanity',
    meta: { layout: true, module: 'broilerChicken' },
    component: () =>
      import(/* webpackChunkName: "slaughterprediction" */ '../views/SanityListView.vue'),
    beforeEnter: (to, from, next) => {
      hasBroilerRoles(to, from, next, router, store, 'Sanidade');
    },
  },
  {
    path: '/nutrition',
    name: 'Nutrition',
    meta: { layout: true, module: 'broilerChicken' },
    component: () => import('../views/NutritionListView.vue'),
    beforeEnter: (to, from, next) => {
      store.state.nutrition.nutritionCardsIsLoaded = false;
      store.state.nutrition.nutritionIsLoaded = false;
      hasBroilerRoles(to, from, next, router, store, 'Nutrição');
    },
  },
  {
    path: '/nutrition/dashboard/:id',
    name: 'NutritionDashboard',
    meta: { layout: true, module: 'broilerChicken' },
    component: () => import('../views/NutritionDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      if (
        store.state.environment.selectedShed == null ||
        store.state.environment.selectedShed.silos != 0
      ) {
        return hasBroilerRoles(to, from, next, router, store, 'Nutrição');
      } else if (store.state.environment.selectedShed.silos == 0) {
        return next(false);
      }
    },
  },
  {
    path: '/matrix/nutrition/dashboard/:id',
    name: 'MatrixNutritionDashboard',
    meta: { layout: true, module: 'broilerChicken' },
    component: () => import('../views/NutritionDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      if (
        store.state.environment.selectedShed == null ||
        store.state.environment.selectedShed.silos != 0
      ) {
        hasMatrixRoles(to, from, next, router, store, 'Nutrição');
      } else if (store.state.environment.selectedShed.silos == 0) {
        return next(false);
      }
    },
  },
  {
    path: '/home',
    name: 'Menu',
    meta: { layout: true, module: 'initial' },
    component: () => import(/* webpackChunkName: "Menu" */ '../views/InitialMenu.vue'),
    beforeEnter: (to, from, next) => {
      isAuthenticated(to, from, next, router);
    },
  },
  {
    path: '/matrix',
    name: 'MatrixPerformance',
    meta: { layout: true, module: 'broilerChicken' },
    component: HomeView,
    beforeEnter: (to, from, next) => {
      hasMatrixRoles(to, from, next, router, store, 'Performance');
    },
  },
  {
    path: '/matrix/sanity',
    name: 'MatrixSanity',
    meta: { layout: true, module: 'broilerChicken' },
    component: () =>
      import(/* webpackChunkName: "slaughterprediction" */ '../views/SanityListView.vue'),
    beforeEnter: (to, from, next) => {
      hasMatrixRoles(to, from, next, router, store, 'Sanidade');
    },
  },
  {
    path: '/matrix/nutrition',
    name: 'MatrixNutrition',
    meta: { layout: true, module: 'broilerChicken' },
    component: () =>
      import(/* webpackChunkName: "slaughterprediction" */ '../views/NutritionListView.vue'),
    beforeEnter: (to, from, next) => {
      store.state.nutrition.nutritionCardsIsLoaded = false;
      store.state.nutrition.nutritionIsLoaded = false;
      hasMatrixRoles(to, from, next, router, store, 'Nutrição');
    },
  },
  {
    path: '/laying_birds/sanity',
    name: 'LayingBirdsSanity',
    meta: { layout: true, module: 'layingBirds' },
    component: () =>
      import(/* webpackChunkName: "slaughterprediction" */ '../views/SanityListView.vue'),
    beforeEnter: (to, from, next) => {
        store.state.environmentFilter.isLayingBirds = true;
        hasLayingBirdsRoles(to, from, next, router, store, 'Sanidade');
    },
  },
  {
    path: '/laying_birds/nutrition',
    name: 'LayingBirdsNutrition',
    meta: { layout: true, module: 'layingBirds' },
    component: () => import('../views/NutritionListView.vue'),
    beforeEnter: (to, from, next) => {
      store.state.nutrition.nutritionCardsIsLoaded = false;
      store.state.nutrition.nutritionIsLoaded = false;
        store.state.environmentFilter.isLayingBirds = true;
        hasLayingBirdsRoles(to, from, next, router, store, 'Nutrição');
    },
  },
  {
    path: '/laying_birds/nutrition/dashboard/:id',
    name: 'LayingBirdsNutritionDashboard',
    meta: { layout: true, module: 'layingBirds' },
    component: () => import('../views/NutritionDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      store.state.environmentFilter.isLayingBirds = true;
      if (
        store.state.environment.selectedShed == null ||
        store.state.environment.selectedShed.silos != 0
      ) {
        return hasLayingBirdsRoles(to, from, next, router, store, 'Nutrição');
      } else if (store.state.environment.selectedShed.silos == 0) {
        return next(false);
      }
    },
  },
  {
    path: '/laying_birds/alerts/recent/dashboard/:id',
    name: 'LayingBirdsAlertDashboard',
    meta: { layout: true, module: 'layingBirds', props: true },
    component: () =>
      import(/* webpackChunkName: "performancedashboard" */ '../views/AlertDashboardView.vue'),
      beforeEnter: (to, from, next) => {
        store.state.environmentFilter.isMatrix = false;
        store.state.environmentFilter.isLayingBirds = true;
        next(false)
      },
  },
  {
    path: '/laying_birds/alerts/history/dashboard/:id',
    name: 'LayingBirdsAlertHistoryDashboard',
    meta: { layout: true, module: 'layingBirds', props: true },
    component: () =>
      import(/* webpackChunkName: "performancedashboard" */ '../views/AlertDashboardView.vue'),
      beforeEnter: (to, from, next) => {
        store.state.environmentFilter.isMatrix = false;
        store.state.environmentFilter.isLayingBirds = true;
        next(false)
      },
  },
  {
    path: '/laying_birds/alerts/recent/',
    name: 'LayingBirdsRecentAlertsView',
    meta: { layout: true, module: 'layingBirds', props: true },
    component: () =>
      import(/* webpackChunkName: "performancedashboard" */ '../views/RecentAlertsView.vue'),
      beforeEnter: (to, from, next) => {
        store.state.environmentFilter.isMatrix = false;
        store.state.environmentFilter.isLayingBirds = true;
        next()
      },
  },
  {
    path: '/laying_birds/alerts/history',
    name: 'LayingBirdsAlertHistoryView',
    meta: { layout: true, module: 'layingBirds', props: true },
    component: () =>
      import(/* webpackChunkName: "performancedashboard" */ '../views/AlertHistoryView.vue'),
      beforeEnter: (to, from, next) => {
        store.state.environmentFilter.isMatrix = false;
        store.state.environmentFilter.isLayingBirds = true;
        next(false)
      },
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
