export const getters = {
  getShedsEnthalpyCount(state) {
    return state.shedsEnthalpyCount;
  },

  getSheds(state) {
    return state.sheds;
  },

  getPcpSheds(state) {
    return state.pcpSheds;
  },

  getTotalSheds(state) {
    return state.totalSheds;
  },

  getShedsIsLoaded(state) {
    return state.shedsIsLoaded;
  },

  getSingleShed(state) {
    return state.singleProperty;
  },

  getSelectedShed(state) {
    return state.selectedShed;
  },

  getSelectedShedAmbientance(state) {
    return state.selectedShedAmbientance;
  },

  getLastBatch(state) {
    return state.lastBatch;
  },

  getDashboardsSettings(state) {
    return state.dashboards;
  },

  getLoadedBatchId(state) {
    return state.loadedBatchId;
  },
};
