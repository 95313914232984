import { authenticate } from '@/services/users';

export const actions = {
  async requestToken({ commit }, { username, password }) {
    let response = await authenticate(username, password);

    if (response) {
      commit('SET_TOKEN', response);
      commit('SET_USER_DATA', response.user)
      // TODO: remover
      commit('SET_ROLES', {...response.user.roles, 'aves_poedeiras': [...response.user.roles['frangos_de_corte'], 'View-Instantaneous', 'View-History']})
    }
    return response;
  },

  resetUserValues({ commit }) {
    commit('SET_TOKEN', null);
    commit('SET_ROLES', {});
    commit('SET_NAME', null);
    commit('SET_IS_GUEST', false);
  },
};
