export const mutations = {
  SET_SHEDS_ENTHALPY_COUNT(state, value) {
    state.shedsEnthalpyCount = value;
  },

  SET_SHEDS(state, value) {
    state.sheds = value;
  },

  SET_PCP_SHEDS(state, value) {
    state.pcpSheds = value;
  },

  SET_TOTAL_SHEDS(state, value) {
    state.totalSheds = value;
  },

  SET_SELECTED_SHED(state, value) {
    state.selectedShed = value;
  },

  SET_SELECTED_SHED_AMBIENTANCE(state, value) {
    state.selectedShedAmbientance = value;
  },

  SET_LAST_BATCH(state, value) {
    state.lastBatch = value;
  },

  SET_DEVICES_LIST(state, value) {
    state.selectedShed.devicesList = value;
  },

  SET_SHEDS_IS_LOADED(state, value) {
    state.shedsIsLoaded = value;
  },

  SET_DASHBOARD_SETTINGS(state, value) {
    state.dashboards = value;
  },

  SET_SINGLE_PROPERTY(state, value) {
    state.singleProperty = value;
  },
  SET_LOADED_BATCH_ID(state, value) {
    state.loadedBatchId = value;
  },
};
