export const state = {
  moduleOpened: null,
  isExpanded: false,
  internalMenuSelected: null,
  defaultRoutes: [
    {
      icon: 'fa-solid fa-house fa-fw',
      label: 'home',
      route: '/home',
      tooltip: {
        content: 'home',
        theme: 'tooltip-right',
        placement: 'right',
      },
      // internal: [
      //   {
      //     title: 'initial',
      //     menus: [
      //       {
      //         icon: 'fa-solid fa-house fa-fw',
      //         label: 'initial',
      //         route: '/home',
      //       },
      //     ],
      //   },
      // ],
    },
    {
      // icon: 'fa-solid fa-egg fa-fw',
      roosterIcon: true,
      disabledIcon: 'fa-solid fa-lock',
      label: 'aves',
      accordion: true,
      tooltip: {
        content: 'broiler_chicken',
        theme: 'tooltip-right',
        placement: 'right',
      },
      accordionMenu: [
        {
          icon: 'fa-solid fa-chart-line fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'performance',
          route: '/',
          isMatrix: false,
          role: 'Performance',
          tooltip: {
            content: 'performance',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
        {
          icon: 'fa-solid fa-table-list fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'slaughter_forecast',
          route: '/slaughter-prediction',
          isMatrix: false,
          role: 'Predição',
          tooltip: {
            content: 'slaughter_forecast',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
        {
          icon: 'fa-solid fa-briefcase-medical fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'sanity',
          route: '/sanity',
          isMatrix: false,
          role: 'Sanidade',
          tooltip: {
            content: 'sanity',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
        {
          icon: 'fa-solid fa-utensils fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'nutrition',
          route: '/nutrition',
          isMatrix: false,
          role: 'Nutrição',
          tooltip: {
            content: 'nutrition',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
        {
          icon: 'fa-solid fa-exclamation-triangle fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'alerts/recent/',
          route: '/alerts/recent/',
          isSidebar: true,
          role: 'View-Instantaneous',
          tooltip: {
            content: 'alerts/recent/',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
        {
          // icon: 'fa-solid fa-history fa-fw',
          icon: 'fa-solid fa-lock',
          disabledIcon: 'fa-solid fa-lock',
          label: 'sanity',
          route: '/alerts/history/',
          isSidebar: true,
          role: 'View-History',
          tooltip: {
            content: 'alerts/history/',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
      ],
      internal: [
        {
          title: 'broiler_chicken',
          isSidebar: true,
          menus: [
            {
              icon: 'fa-solid fa-chart-line fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'performance',
              role: 'Performance',
              isSidebar: true,
              isMatrix: false,
              route: '/',
            },
            {
              icon: 'fa-solid fa-table-list fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'slaughter_forecast',
              role: 'Predição',
              isSidebar: true,
              isMatrix: false,
              route: '/slaughter-prediction',
            },
            {
              icon: 'fa-solid fa-briefcase-medical fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'sanity',
              isSidebar: true,
              isMatrix: false,
              role: 'Sanidade',
              route: '/sanity',
            },
            {
              icon: 'fa-solid fa-utensils fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'nutrition',
              isSidebar: true,
              isMatrix: false,
              role: 'Nutrição',
              route: '/nutrition',
            },
            {
              icon: 'fa-solid fa-exclamation-triangle fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'alerts/recent/',
              role: 'View-Instantaneous',
              isSidebar: true,
              route: '/alerts/recent/',
            },
            {
              // icon: 'fa-solid fa-history fa-fw',
              icon: 'fa-solid fa-lock',
              disabledIcon: 'fa-solid fa-lock',
              label: 'alerts/history/',
              isSidebar: true,
              role: 'View-History',
              route: '/alerts/history/',
            },
          ],
        },
      ],
    },
    {
      icon: 'fa-solid fa-egg fa-fw',
      disabledIcon: 'fa-solid fa-lock',
      label: 'headquarters',
      accordion: true,
      tooltip: {
        content: 'headquarters',
        theme: 'tooltip-right',
        placement: 'right',
      },
      accordionMenu: [
        {
          icon: 'fa-solid fa-chart-line fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'performance',
          route: '/matrix',
          isSidebar: true,
          isMatrix: true,
          role: 'Performance',
          tooltip: {
            content: 'performance',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
        {
          icon: 'fa-solid fa-briefcase-medical fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'sanity',
          route: '/matrix/sanity',
          isSidebar: true,
          isMatrix: true,
          role: 'Sanidade',
          tooltip: {
            content: 'sanity',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
        {
          icon: 'fa-solid fa-utensils fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'nutrition',
          route: '/matrix/nutrition',
          isSidebar: true,
          isMatrix: true,
          role: 'Nutrição',
          tooltip: {
            content: 'nutrition',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
        {
          icon: 'fa-solid fa-exclamation-triangle fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'alerts/recent/',
          route: '/matrix/alerts/recent/',
          isSidebar: true,
          role: 'View-Instantaneous',
          tooltip: {
            content: 'alerts/recent/',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
        {
          // icon: 'fa-solid fa-history fa-fw',
          icon: 'fa-solid fa-lock',
          disabledIcon: 'fa-solid fa-lock',
          label: 'alerts/history/',
          route: '/matrix/alerts/history/',
          isSidebar: true,
          role: 'View-History',
          tooltip: {
            content: 'alerts/history/',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
      ],
      internal: [
        {
          title: 'headquarters',
          isSidebar: true,
          menus: [
            {
              icon: 'fa-solid fa-chart-line fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'performance',
              role: 'Performance',
              isSidebar: true,
              isMatrix: true,
              route: '/matrix',
            },
            {
              icon: 'fa-solid fa-briefcase-medical fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'sanity',
              isSidebar: true,
              isMatrix: true,
              role: 'Sanidade',
              route: '/matrix/sanity',
            },
            {
              icon: 'fa-solid fa-utensils fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'nutrition',
              isSidebar: true,
              isMatrix: true,
              role: 'Nutrição',
              route: '/matrix/nutrition',
            },
            {
              icon: 'fa-solid fa-exclamation-triangle fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'alerts/recent/',
              role: 'View-Instantaneous',
              isSidebar: true,
              route: '/matrix/alerts/recent/',
            },
            {
              // icon: 'fa-solid fa-history fa-fw',
              icon: 'fa-solid fa-lock',
              disabledIcon: 'fa-solid fa-lock',
              label: 'alerts/history/',
              isSidebar: true,
              role: 'View-History',
              route: '/matrix/alerts/history/',
            },
          ],
        },
      ],
    },
    {
      layingBirdsIcon: true,
      disabledIcon: 'fa-solid fa-lock',
      label: 'laying_birds',
      accordion: true,
      tooltip: {
        content: 'laying_birds',
        theme: 'tooltip-right',
        placement: 'right',
      },
      accordionMenu: [
        {
          icon: 'fa-solid fa-briefcase-medical fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'sanity',
          route: '/laying_birds/sanity',
          isMatrix: false,
          isLayingBirds: true,
          role: 'Sanidade',
          tooltip: {
            content: 'sanity',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
        {
          icon: 'fa-solid fa-utensils fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'nutrition',
          route: '/laying_birds/nutrition',
          isMatrix: false,
          isLayingBirds: true,
          role: 'Nutrição',
          tooltip: {
            content: 'nutrition',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
        {
          icon: 'fa-solid fa-exclamation-triangle fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'alerts/recent/',
          route: '/laying_birds/alerts/recent/',
          isSidebar: true,
          role: 'View-Instantaneous',
          tooltip: {
            content: 'alerts/recent/',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
        {
          icon: 'fa-solid fa-history fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'sanity',
          route: '/laying_birds/alerts/history/',
          isSidebar: true,
          role: 'View-History',
          tooltip: {
            content: 'alerts/history/',
            theme: 'tooltip-right',
            placement: 'right',
          },
        },
      ],
      internal: [
        {
          title: 'laying_birds',
          isSidebar: true,
          menus: [
            {
              icon: 'fa-solid fa-briefcase-medical fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'sanity',
              isSidebar: true,
              isLayingBirds: true,
              isMatrix: false,
              role: 'Sanidade',
              route: '/laying_birds/sanity',
            },
            {
              icon: 'fa-solid fa-utensils fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'nutrition',
              isSidebar: true,
              isLayingBirds: true,
              isMatrix: false,
              role: 'Nutrição',
              route: '/laying_birds/nutrition',
            },
            {
              icon: 'fa-solid fa-exclamation-triangle fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'alerts/recent/',
              role: 'View-Instantaneous',
              isSidebar: true,
              route: '/laying_birds/alerts/recent/',
            },
            {
              // icon: 'fa-solid fa-history fa-fw',
              icon: 'fa-solid fa-lock',
              disabledIcon: 'fa-solid fa-lock',
              label: 'alerts/history/',
              isSidebar: true,
              role: 'View-History',
              route: '/laying_birds/alerts/history/',
            },
          ],
        },
      ],
    },
  ],
  routes: [],
  defaultDashboardRoutes: [
    {
      title: 'broiler_chicken',
      isSidebar: false,
      menus: [
        {
          icon: 'fa-solid fa-chart-line fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'performance',
          isSidebar: false,
          route: '/performance/dashboard/',
          role: 'Performance',
        },
        {
          icon: 'fa-solid fa-table-list fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'slaughter_forecast',
          isSidebar: false,
          route: '/slaughter-prediction/dashboard/',
          role: 'Predição',
        },
        {
          icon: 'fa-solid fa-briefcase-medical fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'sanity',
          isSidebar: false,
          route: '/sanity/dashboard/',
          role: 'Sanidade',
        },
        {
          icon: 'fa-solid fa-utensils fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'nutrition',
          isSidebar: false,
          route: '/nutrition/dashboard/',
          role: 'Nutrição',
        },
        {
          icon: 'fa-solid fa-exclamation-triangle fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'alerts/recent/',
          role: 'View-Instantaneous',
          isSidebar: true,
          route: '/alerts/recent/dashboard/',
        },
        {
          // icon: 'fa-solid fa-history fa-fw',
          icon: 'fa-solid fa-lock',
          disabledIcon: 'fa-solid fa-lock',
          label: 'alerts/history/',
          isSidebar: true,
          role: 'View-History',
          route: '/matrix/alerts/history/dashboard/',
        },
      ],
    },
  ],
  layingBirdsDefaultDashboardRoutes: [
    {
      title: 'laying_birds',
      isSidebar: false,
      menus: [
        {
          icon: 'fa-solid fa-briefcase-medical fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'sanity',
          isSidebar: false,
          route: '/laying_birds/sanity/dashboard/',
          role: 'Sanidade',
        },
        {
          icon: 'fa-solid fa-utensils fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'nutrition',
          isSidebar: false,
          route: '/laying_birds/nutrition/dashboard/',
          role: 'Nutrição',
        },
        {
          icon: 'fa-solid fa-exclamation-triangle fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'alerts/recent/',
          role: 'View-Instantaneous',
          isSidebar: true,
          route: '/laying_birds/alerts/recent/dashboard/',
        },
        {
          // icon: 'fa-solid fa-history fa-fw',
          icon: 'fa-solid fa-lock',
          disabledIcon: 'fa-solid fa-lock',
          label: 'alerts/history/',
          isSidebar: true,
          role: 'View-History',
          route: '/laying_birds/alerts/history/dashboard/',
        },
      ],
    },
  ],
  matrixDefaultDashboardRoutes: [
    {
      title: 'matrix',
      isSidebar: false,
      menus: [
        {
          icon: 'fa-solid fa-chart-line fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'performance',
          role: 'Performance',
          accordion: true,
          accordionMenu: [
            {
              icon: 'fa-solid fa-mars fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'male',
              isSidebar: false,
              route: '/matrix/performance/dashboard/',
            },
            {
              icon: 'fa-solid fa-venus fa-fw',
              disabledIcon: 'fa-solid fa-lock',
              label: 'female',
              isSidebar: false,
              route: '/matrix/performance/dashboard/',
            },
          ],
          internal: [
            {
              title: 'headquarters',
              menus: [
                {
                  icon: 'fa-solid fa-mars fa-fw',
                  disabledIcon: 'fa-solid fa-lock',
                  label: 'male',
                  isSidebar: false,
                  route: '/matrix/performance/dashboard/',
                },
                {
                  icon: 'fa-solid fa-venus fa-fw',
                  disabledIcon: 'fa-solid fa-lock',
                  isSidebar: false,
                  label: 'female',
                  route: '/matrix/performance/dashboard/',
                },
              ],
            },
          ],
        },
        {
          icon: 'fa-solid fa-briefcase-medical fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'sanity',
          isSidebar: false,
          route: '/matrix/sanity/dashboard/',
          role: 'Sanidade',
        },
        {
          icon: 'fa-solid fa-utensils fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          isSidebar: false,
          label: 'nutrition',
          route: '/matrix/nutrition/dashboard/',
          role: 'Nutrição',
        },
        {
          icon: 'fa-solid fa-exclamation-triangle fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'alerts/recent/',
          role: 'View-Instantaneous',
          isSidebar: true,
          route: '/matrix/alerts/recent/dashboard/',
        },
        {
          icon: 'fa-solid fa-history fa-fw',
          disabledIcon: 'fa-solid fa-lock',
          label: 'alerts/history/',
          isSidebar: true,
          role: 'View-History',
          route: '/matrix/alerts/history/dashboard/',
        },
      ],
    },
  ],
  dashboardRoutes: [],
  matrixDashboardRoutes: [],
};
