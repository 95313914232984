import { api as axios } from "@/plugins/axios";

export const getSilos = async (token, limit, offset, params) => {
  const config = {
    method: 'get',
    url: '/silo-relation',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      limit: limit,
      offset: offset,
      producer: params.producer,
      filled_area_percentage: params.filled_area_percentage,
      status: params.status,
      matrix: params.matrix,
      ration: params.ration,
      shed: params.shed,
      ordering: params.ordering ?? null,
      laying_birds: params.laying_birds,
      stage: params.stage
    }
  };
  const { data } = await axios(config);
  return data;
}

export const postFeedControl = async (token, payload) => {
  const config = {
    method: 'post',
    url: `/feed-controls`,
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    data: payload
  };
  const { data } = await axios(config);
  return data;
}

export const getSiloOccupationHistory = async (token, id, params = undefined) => {
  const config = {
    method: 'get',
    url: `/silo-occupation-history/${id}`,
    params,
    headers: {
      'Authorization': 'Bearer ' + token,
    },
  };
  const { data } = await axios(config);
  return data;
}

export const getSilosConsumptionHistory = async (token, id, params = undefined) => {
  const config = {
    method: 'get',
    url: `/silos-consumption-history/${id}`,
    params,
    headers: {
      'Authorization': 'Bearer ' + token,
    },
  };
  const { data } = await axios(config);
  return data;
}

export const putFeedControl = async (token, id, payload) => {
  const config = {
    method: 'patch',
    url: `/feed-controls/${id}`,
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    data: payload
  };
  const { data } = await axios(config);
  return data;
}

export const deleteFeedControl = async (token, id) => {
  const config = {
    method: 'delete',
    url: `/feed-controls/${id}`,
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  };
  const { data } = await axios(config);
  return data;
}

export const getSilosShed = async (token, id) => {
  const config = {
    method: 'get',
    url: `/silos-shed/${id}`,
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  };
  const { data } = await axios(config);
  return data;
}

export const getFeedControls = async (token, id, params = {}) => {
  const config = {
    method: 'get',
    url: `/feed-controls`,
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      batch: id,
      ...params
    }
  };
  const { data } = await axios(config);
  return data;
}

export const getSilosList = async (token, limit, offset, params) => {
  const config = {
    method: 'get',
    url: '/silos-list',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      limit: limit,
      offset: offset,
      producer: params.producer,
      filled_area_percentage: params.filled_area_percentage,
      status: params.status,
      matrix: params.matrix,
      laying_birds: params.laying_birds,
      ration: params.ration,
      shed: params.shed,
      ordering: params.ordering ?? null,
      stage: params.stage
    }
  };
  const { data } = await axios(config);
  return data;
}


export const getRationTypes = async (token, companyId) => {
  const config = {
    method: 'get',
    url: '/ration-types',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      company: companyId,
    }
  };
  const { data } = await axios(config);
  return data;
}

