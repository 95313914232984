<template>
  <div class="min-h-100vh flex flex-col flex-shrink-0 drop-shadow-xl z-50">
    <div class="flex flex-row flex-grow">
      <!-- Main -->
      <div class="bg-white w-16 drop-shadow">
        <!-- Header Logo -->
        <div class="flex justify-center items-center py-2 h-14 border-b border-slate-200">
          <img class="w-14" src="@/assets/trinovati-only-logo.png" alt="Trinovati logo">
        </div>

        <!-- Menu -->
        <div class="py-2">
          <nav>
            <ul>
              <router-link v-for="(route, i) in routes" :key="i" :to="route.route || ''">
                <ExternalMenuItem
                  :icon="route.icon || null"
                  :roosterIcon="route.roosterIcon || false"
                  :layingBirdsIcon="route.layingBirdsIcon || false"
                  :disabled="getIsdisabled(route, route.roosterIcon)"
                  :accordion="getAccordion(route, route.roosterIcon)"
                  :accordionMenus="route.accordionMenu"
                  :selected="route.selected"
                  :tooltipContent="route.tooltip"
                  :label="route.label"
                  :hasInternalContent="route.internal != null"
                  :isMatrix="route.isMatrix"
                  :isLayingBirds="route.isLayingBirds"
                  :route="route.role"
                  >
                </ExternalMenuItem>
              </router-link>
              <ExternalMenuItem
                icon="fa-solid fa-arrow-right-from-bracket fa-fw"
                :tooltipContent="{
                  content: 'logout',
                  theme: 'tooltip-right',
                  placement: 'right',
                }"
                @click.native="logout"
                label="logout">
              </ExternalMenuItem>
            </ul>
          </nav>
        </div>

        <!-- Footer -->
        <div class="py-4 border-t border-slate-200 select-none">
          <LanguageDropdown :expanded="false"></LanguageDropdown>
        </div>
        <slot v-bind:allowed="{ internal: getInternalMenuSelected != null }"></slot>
      </div>

      <!-- Side -->
      <div class="bg-white w-40 p-2 h-full" v-if="expandedInternalMenu && getInternalMenuSelected && getInternalMenu() == true">
        <InternalMenu
          v-for="(menu, i) in getInternalMenuSelected.internal" :key="i"
          :isSidebar="menu.isSidebar"
          :title="menu.title"
          :menus="menu.menus">
        </InternalMenu>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageDropdown from '@/components/ui/LanguageDropdown.vue';
import ExternalMenuItem from '@/components/sidebar/ExternalMenuItem.vue';
import InternalMenu from '@/components/sidebar/InternalMenu.vue';

import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'NewSideBar',
  components: { LanguageDropdown, ExternalMenuItem, InternalMenu },
  props: {
    expanded: { type: Boolean, default: false },
    expandedInternalMenu: { type: Boolean, default: false },
    routes: { type: Array, default: () => [] },
  },
  computed: {
    ...mapGetters('interface', ['getModuleOpened', 'getInternalMenuSelected']),
    ...mapGetters('user', ['getBroilerRoles', 'getToken', 'getMatrixRoles', 'getLayingBirdsRoles']),
    moduleOpened: {
      get() {
        return this.getModuleOpened;
      },
      set(value) {
        this.SET_MODULE_OPENED(value);
      },
    },
  },
  methods: {
    ...mapActions('environment', ['resetPropertiesValues']),
    ...mapActions('comfort', ['resetEnthalpyValues']),
    ...mapActions('nutrition', ['resetNutritionValues']),
    ...mapActions('user', ['resetUserValues']),
    ...mapMutations('interface', ['SET_MODULE_OPENED']),
    getIsdisabled(route, rooster) {
        if (route.label == 'home') {
          return false
        }
        else if(rooster && this.getBroilerRoles == null && route.route == undefined)
        {
          return true
        }
        else if(!rooster && this.getMatrixRoles == null && route.route == undefined)
        {
          return true
        }
        else if(!rooster && this.getLayingBirdsRoles == null && route.route == undefined)
        {
          return true
        }
        else if (this.getBroilerRoles != null && this.getBroilerRoles.includes(route.role && route.isMatrix == false)) {
          return false
        }
        else if (this.getMatrixRoles != null && this.getMatrixRoles.includes(route.role && route.isMatrix == true)) {
          return false
        }
        else if (this.getLayingBirdsRoles != null && this.getLayingBirdsRoles.includes(route.role)) {
          return false
        }
    },
    getAccordion(route, rooster) {
        if(rooster && this.getBroilerRoles == null && route.route == undefined) {
          return false
        } else if(!rooster && this.getMatrixRoles == null && route.route == undefined) {
          return false
        } else if(!rooster && this.getLayingBirdsRoles == null && route.route == undefined) {
          return false
        }
        else return route.accordion
    },
    getInternalMenu() {
      if(this.getInternalMenuSelected != null) {
        if (this.getBroilerRoles != null && this.getInternalMenuSelected.label == "aves") {
          return true
        } else if (this.getMatrixRoles != null && this.getInternalMenuSelected.label == "headquarters") {
          return true
        } else if (this.getLayingBirdsRoles != null && this.getInternalMenuSelected.label == "laying_birds") {
          return true
        } else if (this.getInternalMenuSelected.label == "alert") {
          return true
        } else return false
      }
    },
    logout() {
      this.resetPropertiesValues();
      this.resetEnthalpyValues();
      this.resetNutritionValues();
      this.resetUserValues();
      this.$session.destroy();
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss">
  @use '@/assets/styles/sidebar.scss';

  .min-h-100vh {
    min-height: 100vh !important;
  }
</style>
