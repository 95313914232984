import { api as axios } from '@/plugins/axios.js';

export const getSheds = async (token, limit=null, offset=null, params=null, state=null) => {
  const config = {
    method: 'get',
    url: '/sheds/list',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      platform: true,
      limit: limit,
      offset: offset,
      state: state,
      stage: params.stage,
      enthalpy: params.enthalpy,
      lineage: params.lineage,
      sex: params.sex,
      producer: params.producer,
      age: params.age,
      performance: params.performance,
      variation_coef: params.variation_coef,
      weight: params.weight,
      priority: params.priority ? params.priority : null,
      matrix: params.matrix ? params.matrix : null,
      laying_birds: params.laying_birds,
      predicted_weight: params.predicted_weight,
      predicted_weight_date: params.predicted_weight_date,
      ordering: params.ordering ?? null
    }
  };
  const { data } = await axios(config);
  return data;
};

export const getShedDetails = async (token, id, params = undefined) => {
  const config = {
    method: 'get',
    url: `silos-detail/${id}`,
    params,
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  };
  const { data } = await axios(config);
  return data;
}


export const getShedDetailsOld = async (token, id) => {
  const config = {
    method: 'get',
    url: `sheds/${id}/detail`,
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  };
  const { data } = await axios(config);
  return data;
}

export const getShedBatches = async (token, shedId) => {
  const config = {
    method: 'get',
    url: '/batches',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      'shed': shedId,
    }
  };
  const { data } = await axios(config);
  return data;
}

export const getShedBatchesNutrition = async (token, shedId) => {
  const config = {
    method: 'get',
    url: '/batches-shed/'+ shedId,
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  };
  const { data } = await axios(config);
  return data;
}

export const getDevices = async (token, shedId, offset) => {
  const config = {
    method: 'get',
    url: '/devices',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      'shed_id': shedId,
      'offset': offset
    }
  };
  const { data } = await axios(config);
  return data;
};

export const getBoxDevices = async (token, offset) => {
  const config = {
    method: 'get',
    url: '/device-box',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      'offset': offset
    }
  };
  const { data } = await axios(config);
  return data;
}
